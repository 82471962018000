<template>
    <div class="center">
        <img :src="require('@/assets/images/spinner.svg')">
        <p style="color:rgb(251, 177, 205);font-weight:bold;font-size:18px;">Merci de patienter, vous allez être redirigé.</p>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            ready: true,
        }
    },
    components: {
    },
    beforeMount() {
    },
    mounted() {
        this.$gtag.event('attestation_active', { method: 'attestation_active' });
        setTimeout(() => {
            location.href = 'https://pautions.fr/proprietaire.html';
        }, 1000);
    },
    computed: {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style media="screen">
.center {
    text-align: center;
    position: fixed;
    z-index: 100;            
    height: 200px;
    padding: 20px;                        
    /* Center the dialog */
    top: 40%;
    left: 45%;
    transform: translate(-50%, -50%);
}
</style>
  